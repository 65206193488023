import Button from "../../../elements/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import React, { useEffect, useState } from "react";
import {
  changeSsnEight,
  changeSsnNine,
  changeSsnSeven,
  changeSsnSix,
  setStep,
} from "../../../redux/sliceAuth";
import { getAuth } from "firebase/auth";
import SignupNavbar from "../../../elements/SignupNavbar/SignupNavbar";
import { StepWizardChildProps } from "react-step-wizard";
import { useUserEditMutation } from "../../../api/apiAuth";
import "./demoSsn.css";
import Wrapper from "../../../elements/Wrapper";
// @ts-ignore
import MultiInput from "../../../elements/MultiInput/MultiInput";
import { useMixpanel } from "../../../helpers/mixpanel";
import { useNavigate } from "react-router-dom";
import DemoAccountVerification from "../DemoAccountVerification/DemoAccountVerification";

const DemoSsn: React.FC<Partial<StepWizardChildProps>> = ({}) => {
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();
  const auth = getAuth();
  const authInfo: any = useSelector<RootState>((state) => state.auth);
  const hasSavedSsn =
    authInfo?.ssnSix &&
    authInfo?.ssnSeven &&
    authInfo?.ssnEight &&
    authInfo?.ssnNine;
  const savedSsn = hasSavedSsn
    ? `${authInfo?.ssnSix}${authInfo?.ssnSeven}${authInfo?.ssnEight}${authInfo?.ssnNine}`
    : "";
  const [ssn, setSsn] = useState(savedSsn);
  const [formError, setFormError] = useState({
    ssn: false,
  });
  const [editUser, editUserResult] = useUserEditMutation();
  const navigate = useNavigate();
  const [showAnimationVerification, setShowAnimationVerification] =
    useState(false);
  const isSsnValid = (ssn: string) => {
    return ssn && ssn.length === 4;
  };

  const resetErrorOnChange = () => {
    setFormError({
      ssn: false,
    });
  };

  const onBackHandler = () => {
    navigate("/demo-address");
  };

  const onNextHandler = () => {
    if (!isSsnValid(ssn)) {
      setFormError({ ssn: true });
    } else {
      dispatch(changeSsnSix(ssn[0]));
      dispatch(changeSsnSeven(ssn[1]));
      dispatch(changeSsnEight(ssn[2]));
      dispatch(changeSsnNine(ssn[3]));

      auth.currentUser?.getIdToken().then(async (tkn) => {
        const body = { ssn: ssn };
        await editUser({ tkn, body }).then((res: any) => {
          if (res.error) {
            console.log("Error in editUser SignUpSS.tsx: ", res.error);
          } else {
            setShowAnimationVerification(true); 
            mixpanel("KYC_SSN", {}, tkn);

            setTimeout(() => {
              navigate("/demo-dashboard");
            }, 3000);
          }
        });
      });
    }
  };


  return (
    <>
      {showAnimationVerification ? (
        <DemoAccountVerification />
      ) : (
        <Wrapper>
          <SignupNavbar
            step={8}
            handler={onBackHandler}
            showBackButton={true}
          />
          <div className="page__infoContainer">
            <div className="page__title-width">
              <div className="page__title smaller-size">
                Please enter the last 4 digits of your social security number.
              </div>
            </div>
            <div className="ssn__input__holder">
              <MultiInput
                placeholder="----"
                numInputs={4}
                value={ssn}
                error={formError.ssn}
                onChange={(value: any) => {
                  setSsn(value);
                  resetErrorOnChange();
                }}
              />
            </div>
            {formError.ssn ? (
              <div className="info__subtitle error__msg">
                Please enter the last four digits of your SSN.
              </div>
            ) : (
              <>
                <div className="info__subtitle">
                  We are required by law to ask these questions.
                </div>
                <div className="info__subtitle">
                  This will help us keep your account secure.
                </div>
              </>
            )}
          </div>
          <div className="button__holder">
            <Button
              onClick={onNextHandler}
              type={"primary-btn"}
              style={{ opacity: 1 }}
              disabled={editUserResult.isLoading}
            >
              Continue
            </Button>
          </div>
          <input
            type="hidden"
            id="abandonedOnboardingStage"
            value={"kyc_ssn"}
          />
        </Wrapper>
      )}
    </>
  );
};

export default DemoSsn;
