import Dialog from "@mui/material/Dialog";

import closeBtn from "../../assets/closeBtn.png";
import BankCard from "../BankCard/BankCard";
import PlaidAuth from "../../pages/Plaid/PlaidAuth";
import { useMixpanel } from "../../helpers/mixpanel";
import { AccountType } from "../../helpers/types";
import { useEffect, useState } from "react";
interface ModalAccountsListProps {
  accountsList: any;
  setAccount: React.Dispatch<any>;
  open: boolean;
  onClose: () => void;
  title: string;
  relationType: string;
  verifyAccountsSubtype?: (accounts: any[], institution: any) => void;
  accountLoadErrorCallback?: (errorMsg: string, type: string) => void;
  onPlaidAuthSuccess: (accounts: any) => void;
  setIsPlaidAuthLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  currentAutomation?: any;
}

const ModalAccountsList = ({
  accountsList,
  setAccount,
  open,
  onClose,
  title,
  verifyAccountsSubtype,
  accountLoadErrorCallback,
  onPlaidAuthSuccess,
  setIsPlaidAuthLoading,
  relationType,
  currentAutomation,
}: ModalAccountsListProps) => {
  const [isEnabledAutomationMap, setIsEnabledAutomationMap] = useState(
    new Map(),
  );

  useEffect(() => {
    const newMap = new Map();
    accountsList.forEach((account: any) => {
      newMap.set(account.id, account.isUsedInAutomation);
    });
    setIsEnabledAutomationMap(newMap);
  }, [accountsList]);

  const updateIsEnabledAutomation = (accountId: string, isEnabled: boolean) => {
    setIsEnabledAutomationMap((prev) => prev.set(accountId, isEnabled));
  };

  const mixpanel = useMixpanel();
  const onSelectAccount = (account: any) => {
    if (isEnabledAutomationMap.get(account.id)) {
      setAccount(account);

      if (relationType === AccountType.CHECKING) {
        mixpanel("Connected_checking_account", { Banks: account });
      } else if (relationType === "savings") {
        mixpanel("Connected_savings_account", { Banks: account });
      }
      onClose();
    } else {
      return;
    }
  };

  return (
    <Dialog
      onClose={onClose}
      open={open}
      classes={{
        root: "add-automation-dialog",
        paper: "add-automation-dialog-paper",
      }}
    >
      <div className="closeModalBtn">
        <img src={closeBtn} alt="close button" onClick={onClose} />
      </div>
      <div className="text-center" style={{ width: "100%" }}>
        <PlaidAuth
          accountType={`Add New ${title}`}
          typeBtn="modalPlaid-btn"
          success={onPlaidAuthSuccess}
          setIsLoading={setIsPlaidAuthLoading}
          preConnectedAccountsCB={verifyAccountsSubtype}
          relationType={relationType}
          accountLoadErrorCallback={accountLoadErrorCallback}
        />
      </div>
      <div className="modalAccountsList__title">{`Available ${title}s`}</div>
      <div className="modalAccountsList__cardsHolder">
        {accountsList?.map((account: any) => (
          <BankCard
            updateIsEnabledAutomation={updateIsEnabledAutomation}
            isEnabledAutomationMap={isEnabledAutomationMap}
            currentAutomation={currentAutomation}
            key={account.plaidAccountId}
            account={account}
            onSelectAccount={onSelectAccount}
          />
        ))}
      </div>
    </Dialog>
  );
};

export { ModalAccountsList };
