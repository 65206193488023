import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  checkingAccountList: [],
  savingsAccountList: [],
  selectedAccount: {},
};

export const sliceAccounts = createSlice({
  name: "accounts",
  initialState: initialState as any,
  reducers: {
    // NEW
    setSavingsAccountList: (state, { payload }) => {
      state.savingsAccountList = payload;
    },
    setCheckingAccountList: (state, { payload }) => {
      state.checkingAccountList = payload;
    },
    removeAccounts: (state) => {
      state.checkingAccountList = [];
      state.savingsAccountList = [];
    },
    updateAllIsUsedAutomationCheckingAccount: (state, isUsedInAutomation) => {
      state.checkingAccountList.forEach((account: any) => {
        account.isUsedInAutomation = isUsedInAutomation;
      });
    },
    setSelectedAccount: (state, { payload }) => {
      state.selectedAccount = payload;
    },
    resetAccounts: (state) => Object.assign(state, initialState),
  },
});

export const {
  setCheckingAccountList,
  setSavingsAccountList,
  removeAccounts,
  updateAllIsUsedAutomationCheckingAccount,
  setSelectedAccount,
  resetAccounts,
} = sliceAccounts.actions;
export default sliceAccounts.reducer;
