import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { api, apiReducer } from "../api/api";
import auth from "./sliceAuth";
import accounts from "./sliceAccounts";
import userAutomation from "./sliceUserAutomation";
import userNotifications from "./sliceNotifications";
import appState from "./sliceAppState";
import creditCards from "./sliceCreditCards";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
  //   whitelist: ['auth', 'accounts', 'userAutomation'],
  blacklist: ["api"],
};

// Combine all reducers
const rootReducer = combineReducers({
  api: apiReducer,
  auth,
  accounts,
  userAutomation,
  userNotifications,
  appState,
  creditCards,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
      },
    }).concat(api.middleware),
  devTools: true,
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
