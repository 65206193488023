import { ReactNode } from "react";
import "./button.css";
import classNames from "classnames";

// use in Create Account Flow

// prop type:
// black-btn --> #303030 background, #FAFAFA text
// white-btn --> #FAFAFA background, #303030 text
// prop style: for inline style

// dark theme
// primary-btn --> #303030 background, #FAFAFA text
// secondary-btn ---> #303030 background, #FAFAFA text

type ButtonProp = {
  children: ReactNode;
  onClick?: () => void;
  type?: string;
  style?: Object;
  disabled?: boolean;
  className?: string;
  onMouseDown?: () => void;
};

function Button({
  children,
  onClick,
  type = "",
  style = {},
  disabled = false,
  className,
  onMouseDown,
}: ButtonProp) {
  return (
    <>
      <button
        className={classNames(
          disabled
            ? ["btn", type, "disabled"].join(" ")
            : ["btn", type].join(" "),
          className,
        )}
        style={style}
        onClick={onClick}
        disabled={disabled}
        onMouseDown={onMouseDown}
      >
        {children}
      </button>
    </>
  );
}

export default Button;
