import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import "./index.css";
import "./config/firebase";
import App from "./App";
import ScrollToTop from "./helpers/scrollToTop";

import { PersistGate } from "redux-persist/integration/react";

declare global {
  interface Window {
    mixpanel: any;
    FS: any;
  }
}
const STAGE_TOKEN = "d79341a5ff9467850970e1f7585d5be0";
const PROD_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <ScrollToTop />
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>,
);
