import { useNavigate } from "react-router-dom";
import Button from "../../../elements/Button/Button";
import CreditCard from "../../../elements/CreditCard/CreditCard";
import { useEffect, useState } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
// import { setCreditCards } from "../../../redux/sliceUserAutomation";
import { setSelectedCreditCards } from "../../../redux/sliceCreditCards";
import { StepWizardChildProps } from "react-step-wizard";
import Wrapper from "../../../elements/Wrapper";
import SignupNavbar from "../../../elements/SignupNavbar/SignupNavbar";
import { useGetCardsQuery } from "../../../api/apiLiability";

import "../liabilityFlow.css";
import "./DemoChooseCreditCrads.css";
import "../demo.css";
const DemoChooseCreditCrads: React.FC<Partial<StepWizardChildProps>> = () => {
  const {
    data: creditCardsData,
    isLoading: isLoadingCards,
    isError: LoadCardsError,
    error: CardsError,
  } = useGetCardsQuery(undefined);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedAccounts, setSelectedAccounts] = useState<number[]>([]);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [creditCards, setCreditCard] = useState<any[]>([]);

  const handleSelectCard = (cardId: number) => {
    setError(false);
    setErrorMessage("");

    setSelectedAccounts((prevSelected) =>
      prevSelected.includes(cardId)
        ? prevSelected.filter((id) => id !== cardId)
        : [...prevSelected, cardId],
    );

    const clickedCard = creditCardsData?.find(
      (card: any) => card.id === cardId,
    );

    if (clickedCard) {
      setCreditCard((prevCreditCards) => {
        const isSelected = prevCreditCards.some(
          (card) => card.id === clickedCard.id,
        );

        if (isSelected) {
          return prevCreditCards.filter((card) => card.id !== clickedCard.id);
        } else {
          return [...prevCreditCards, clickedCard];
        }
      });
    }
  };

  const onNextHandler = () => {
    if (selectedAccounts.length < 1) {
      setError(true);
      setErrorMessage(
        "Please choose at least one credit card for your automation.",
      );
      return;
    }
    // debugger;
    // dispatch(setCreditCards(creditCards));
    dispatch(setSelectedCreditCards(creditCards));
    navigate("/set-up-automation");
  };

  const onBackHandler = () => {
    navigate("/demo-date-birth");
  };

  return (
    <Wrapper>
      <SignupNavbar step={5} showBackButton={true} handler={onBackHandler} />
      <div className="page__infoContainer">
        <div className="container_choose_forspacings">
          <div>
            <div className="page__title liability_form_input_title">
              Now, let’s set up your automation.
            </div>
            <div className="page__subtitle liability_for_input_subTitle">
              First, please choose the credit cards that you want to include in
              your automation.
            </div>
          </div>
          <div
            className={classNames(
              "bankCards_container",
              error && "bankCards_container_with_error",
            )}
          >
            <div className="scrollable_container">
              {!isLoadingCards &&
                creditCardsData?.map((account: any, index: any) => (
                  <div key={index}>
                    <CreditCard
                      statementBalance={account.statementBalance}
                      isChecked={selectedAccounts.includes(account.id)}
                      card={account}
                      className={
                        selectedAccounts.includes(account.id)
                          ? "selectedAccount"
                          : "nonSelectedAccount"
                      }
                      onSelectCard={() => handleSelectCard(account.id)}
                    />
                  </div>
                ))}
            </div>
          </div>
          {error && (
            <div className="userName__errorMassage error__message demo_form_error">
              {errorMessage}
            </div>
          )}
        </div>
      </div>
      <div className="button__holder">
        <Button
          onClick={onNextHandler}
          type={"primary-btn"}
          style={{ opacity: 1 }}
        >
          Continue
        </Button>
      </div>
    </Wrapper>
  );
};

export default DemoChooseCreditCrads;
