import { api } from "./api";
import { Decimal } from "decimal.js";

interface LiabilityAutomation {
  id: string;
  userId: string;
  accountId: string;
  createdAt: string;
  status: string;
  updatedAt: string;
  userDefinedMaxPayment: number;
  optimizationMethod: string;
  accountBalance: number;
  creditCards: CreditCard[];
  optimizedPayments: PaymentResultRevised;
}

interface CreditCard {
  id: string;
  creditCardNumberMasked: string;
  creditLimit: number;
  creditUtilization: number;
  outstandingBalance: number;
  statementBalance: number;
  minimumPaymentAmount: number;
  dueDate: string;
  originalName: string;
  apr: number;
  bank: string;
}

interface Payment {
  id: string;
  creditCardNumberMasked: string;
  paymentAmount: number;
  reason: string;
  apr: number;
  creditUtilizationRatio: Decimal;
  minimumPaymentAmount: Decimal;
  outstandingBalance: Decimal;
  statementBalance: Decimal;
  creditLimit: Decimal;
  dueDate: string;
  originalName: string;
  bank: string;
}

interface ReturnCard {
  cardID: string;
  cardMask: string;
  creditLimit: number;
  creditUtilization: number;
  outstandingBalance: number;
  statementBalance: number;
  minimumPaymentAmount: number;
  dueDate: string;
  originalName: string;
  apr: number;
  paymentAmount: number;
  reason: string;
  remainingStatementBalance: number;
  newOutstandingBalance: number;
  bank: string;
}

interface PaymentResultRevised {
  originalBudget: number;
  totalPaymentsMade: number;
  remainingBudget: number;
  cards: ReturnCard[];
}

interface CreateLiabilityAutomationRequest {
  userId: string;
  accountId: string;
  fixedValue?: number;
  status?: string;
  userDefinedMaxPayment: number;
  optimizationMethod: string;
  accountBalance: number;
  creditCards: Array<{
    creditCardNumberMasked: string;
    creditLimit: number;
    creditUtilization: number;
    outstandingBalance: number;
    statementBalance: number;
    minimumPaymentAmount: number;
    dueDate: string;
    originalName: string;
    apr: number;
  }>;
}

interface UpdateLiabilityAutomationRequest {
  id: string;
  userId: string;
  updateData: Partial<CreateLiabilityAutomationRequest>;
}

interface GetLiabilityAutomationRequest {
  id: string;
  userId: string;
}

interface UserLiabilitiesAPIResponse<T> {
  error: null | string;
  status: number;
  liabilityAutomations?: T[];
  liabilityAutomation?: T;
}
interface UserLiabilityAPIResponse<T> {
  error: null | string;
  status: number;
  liabilityAutomation?: T;
}

interface CardsAPIResponse {
  error: null | string;
  status: number;
  cards: CreditCard[];
}

interface CreateAutomationAPIResponse {
  error: null | string;
  status: number;
  liabilityAutomation: LiabilityAutomation;
}

const apiLiabilityAutomation = api.injectEndpoints({
  endpoints: (build) => ({
    // Create liability automation
    createLiabilityAutomation: build.mutation<
      LiabilityAutomation,
      CreateLiabilityAutomationRequest
    >({
      query: (body) => ({
        url: "/credit-cards",
        method: "POST",
        body,
      }),
      transformResponse: (response: CreateAutomationAPIResponse) => {
        if (!response.liabilityAutomation) {
          throw new Error("Liability automation not found in response");
        }
        return response.liabilityAutomation;
      },
    }),

    // Get single liability automation
    getLiabilityAutomation: build.query<
      LiabilityAutomation,
      GetLiabilityAutomationRequest
    >({
      query: ({ id, userId }) => ({
        url: `/credit-cards/${id}`,
        params: { userId },
      }),
      transformResponse: (
        response: UserLiabilityAPIResponse<LiabilityAutomation>,
      ) => {
        if (!response.liabilityAutomation) {
          throw new Error("Liability automation not found in response");
        }
        return response.liabilityAutomation;
      },
    }),

    // Get all liability automations for a user
    getUserLiabilityAutomations: build.query<LiabilityAutomation[], string>({
      query: (userId) => ({
        url: `/credit-cards`,
        params: { userId },
      }),
      transformResponse: (
        response: UserLiabilitiesAPIResponse<LiabilityAutomation>,
      ) => response.liabilityAutomations || [],
    }),

    // Update liability automation
    updateLiabilityAutomation: build.mutation<
      LiabilityAutomation,
      UpdateLiabilityAutomationRequest
    >({
      query: ({ id, userId, updateData }) => ({
        url: `/credit-cards/${id}`,
        method: "PUT",
        body: {
          userId,
          ...updateData,
        },
      }),
      transformResponse: (response: {
        data: { liabilityAutomation: LiabilityAutomation };
      }) => response.data.liabilityAutomation,
    }),

    // Calculate transfers
    calculateTransfers: build.mutation({
      query: (body) => ({
        url: "/credit-cards/calculate-transfers",
        method: "POST",
        body,
      }),
    }),

    // Get credit cards
    getCards: build.query<CreditCard[], void>({
      query: () => ({
        url: "/credit-cards/cards",
      }),
      transformResponse: (response: CardsAPIResponse) => {
        if (!response.cards) {
          throw new Error("Cards not found in response");
        }
        return response.cards;
      },
    }),
  }),

  overrideExisting: false,
});

export const {
  useCreateLiabilityAutomationMutation,
  useGetLiabilityAutomationQuery,
  useLazyGetLiabilityAutomationQuery,
  useGetUserLiabilityAutomationsQuery,
  useLazyGetUserLiabilityAutomationsQuery,
  useUpdateLiabilityAutomationMutation,
  useCalculateTransfersMutation,
  useGetCardsQuery,
  useLazyGetCardsQuery,
} = apiLiabilityAutomation;

export default apiLiabilityAutomation;
