import { Link, useNavigate } from "react-router-dom";
import Button from "../../../elements/Button/Button";
import CreditCard from "../../../elements/CreditCard/CreditCard";
import arrows from "../../../assets/oneDirectionsArrows.png";
import { useContext, useEffect, useState } from "react";
import { AccountType } from "../../../helpers/types";
import PlaidAuth from "../../Plaid/PlaidAuth";
import { AuthContext } from "../../../context/AuthContext";
import classNames from "classnames";
import DemoAccountCard from "../../../elements/DemoAccountCard/DemoAccountCard";
import Wrapper from "../../../elements/Wrapper";
import SignupNavbar from "../../../elements/SignupNavbar/SignupNavbar";
import { StepWizardChildProps } from "react-step-wizard";
import AutomationSetWrapUp from "../AutomationSetWrapUp/AutomationSetWrapUp";
import DemoMaximalInput from "../../../elements/DemoMaximalInput/DemoMaximalInput";
import { DemoModalAccountsList } from "../../../elements/Modals/DemoModalAccountsList";

//api
import { useLazyGetUserAccountsQuery } from "../../../api/apiPlaid";
import { useCreateLiabilityAutomationMutation } from "../../../api/apiLiability";

//redux
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import {
  setLiabilityAutomation,
  selectLiabilityAutomation,
  setUserDefinedMaxPayment,
  setOptimizationMethod,
  selectBankAccount,
  selectCreditCards,
  setSelectAccount,
  setMaximalAmount,
  setShowOptimizationBlock,
  selectShowOptimizationBlock,
  selectSelectAccount,
  selectMaximalAmount,
  selectSelectedCreditCards,
  selectOptimizationMethod,
  setNotChosenOptimizationMethod,
} from "../../../redux/sliceCreditCards";
import { setBankAccount } from "../../../redux/sliceCreditCards";
import {
  setCheckingAccountList,
  setSavingsAccountList,
} from "../../../redux/sliceAccounts";

//css
import "./creditCardAutomationSetUp.css";

const CreditCardAutomationSetUp: React.FC<
  Partial<StepWizardChildProps>
> = ({}) => {
  const context = useContext(AuthContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //redux selectors
  const checkingAccounts: any = useSelector<RootState>(
    (state) => state.accounts.checkingAccountList,
  );
  const savingsAccounts: any = useSelector<RootState>(
    (state) => state.accounts.savingsAccountList,
  );
  const selectedLiabilityAutomation = useSelector(selectLiabilityAutomation);
  const reduxCreditCards = useSelector(selectCreditCards);
  const selectedBankAccount = useSelector(selectBankAccount);
  //   const selectAccount = useSelector(selectSelectAccount);
  const maximalAmount = useSelector(selectMaximalAmount);
  const showOptimizationBlock = useSelector(selectShowOptimizationBlock);
  const creditCards = useSelector(selectSelectedCreditCards);
  const optimizationMethod = useSelector(selectOptimizationMethod);
  const bankAccount = useSelector(selectBankAccount);

  //API
  const [getAccountsList, accountsListResult] = useLazyGetUserAccountsQuery();
  const [createAutomation, { data: createdAutomation, isLoading: isCreating }] =
    useCreateLiabilityAutomationMutation();

  //Component State
  const [isPlaidAuthOpen, setIsPlaidAuthOpen] = useState(false);
  const [typeAcc, setTypeAcc] = useState("");
  const [isPlaidAuthLoading, setIsPlaidAuthLoading] = useState(false);
  const [accountLoadMsg, setAccountLoadMsg] = useState("");
  const [maximalAmountError, setMaximalAmountError] = useState(false);
  const [showAccountsPopUp, setShowAccountsPopUp] = useState(false);
  const [showMaximalValue, setShowMaximalValue] = useState(false);
  const [finishAutomationFlow, setFinishedAutomationFlow] = useState(false);
  const [accountError, setAccountError] = useState(false);
  const [accountErrorMessage, setAccountErrorMessage] = useState("");
  const [continueButtonClicked, setContinueButtonClicked] = useState(false);
  const [backButtonClicked, setBackButtonClicked] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [continueButtonClicked, backButtonClicked]);

  useEffect(() => {
    if (finishAutomationFlow) {
      const timer = setTimeout(() => {
        dispatch(setShowOptimizationBlock(true));
        navigate("/demo-address");
      }, 1500);
      return () => clearTimeout(timer);
    }
  }, [finishAutomationFlow]);

  useEffect(() => {
    if (bankAccount && !maximalAmount) {
      dispatch(setMaximalAmount(bankAccount?.availableBalance * 0.6));
    }
  }, [bankAccount]);

  useEffect(() => {
    getAccounts();
  }, []);

  const onPlaidAuthSuccess = (justConnectedAccounts: any[]) => {
    applyDefaultAccounts(justConnectedAccounts);
    setIsPlaidAuthOpen(false);
  };

  const onPlaidAuthExit = () => {
    setIsPlaidAuthOpen(false);
  };

  useEffect(() => {
    if (accountsListResult.data) {
      let checkingAccounts: Array<string> = [];
      let savingsAccounts: Array<string> = [];

      accountsListResult?.data?.data.forEach((item: any) => {
        if (item.cacheType === "savings") {
          savingsAccounts.push(item);
        } else if (item.cacheType === AccountType.CHECKING) {
          checkingAccounts.push(item);
        }
      });
      dispatch(setCheckingAccountList(checkingAccounts));
      dispatch(setSavingsAccountList(savingsAccounts));
    } else {
      return;
    }
  }, [accountsListResult]);

  const formatAmount = (amount: string) => {
    const number = parseFloat(amount.replace(/,/g, ""));
    return isNaN(number)
      ? amount
      : number.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
  };

  const recommendedMaximalValue = formatAmount(
    (bankAccount?.availableBalance * 0.6).toString(),
  );

  const accountLoadErrorCallback = (errorMsg: string, type: string) => {
    setAccountLoadMsg(errorMsg);
    setIsPlaidAuthOpen(false);
  };

  const handleCreateAutomation = async () => {
    try {
      const { user } = context;
      const userId = user?.uid || "";
      const liabilityAutomationResponse = await createAutomation({
        userId,
        accountId: "default",
        status: "active",
        userDefinedMaxPayment: +maximalAmount,
        optimizationMethod: optimizationMethod,
        accountBalance: bankAccount?.availableBalance,
        creditCards,
      }).unwrap();
      dispatch(setLiabilityAutomation(liabilityAutomationResponse));
    } catch (error) {
      console.error("Failed to create automation:", error);
      alert("Failed to create automation");
    }
  };

  const handleModalOpen = () => {
    document.body.classList.remove("reset-mui-overflow");
    if (checkingAccounts.length > 0 || savingsAccounts.length > 0) {
      setShowAccountsPopUp(true);
    }
  };

  const handleModalClose = () => {
    document.body.classList.add("reset-mui-overflow");
    setShowAccountsPopUp(false);
  };

  const onBackHandler = () => {
    if (bankAccount) {
      dispatch(setBankAccount(null));
    } else if (bankAccount === null) {
      navigate("/choose-accounts");
    }
    if (showOptimizationBlock) {
      setBackButtonClicked(true);
      dispatch(setShowOptimizationBlock(false));
      dispatch(setMaximalAmount(0));
    }
  };

  const applyDefaultAccounts = async (accounts: any[]) => {
    try {
      if (accounts.length === 0) {
        return null;
      }

      if (accounts.length > 1) {
        handleModalOpen();
        dispatch(setSelectAccount(Object.assign({})));
        await getAccounts();
        return;
      }

      if (accounts.length === 1) {
        const defaultCheckingAccount = accounts[0];
        dispatch(setSelectAccount(defaultCheckingAccount));
        // setSelectAccount(defaultCheckingAccount);
        setShowMaximalValue(true);
        getAccounts(defaultCheckingAccount);
        dispatch(setBankAccount(defaultCheckingAccount));
        return;
      }

      if (accounts.length === 0) {
        getAccounts();
        return;
      }
    } catch (error) {
      console.log("Error in applyDefaultAccounts AddAutomation.tsx: ", error);
    }
  };

  const getAccounts = async (accountToUpdate?: {
    [field: string]: any | null;
  }) => {
    try {
      const { user } = context;
      if (!user) {
        return;
      } else {
        await user.getIdToken().then(async (idToken: string) => {
          const result = await getAccountsList({ idToken });
          const accountsResult = result?.data?.data;
          if (accountsResult && accountToUpdate) {
            const extendedAccount = accountsResult.find(
              ({ plaidAccountId }: { plaidAccountId: string }) =>
                plaidAccountId === accountToUpdate.plaidAccountId,
            );
            dispatch(setSelectAccount(extendedAccount));
          }
        });
      }
    } catch (error) {
      console.log("error in getAccounts DashboardAddAutomation.tsx: ", error);
    }
  };

  const onNextHandler = () => {
    const regex = /^\d+$/;
    if (!bankAccount && !maximalAmount) {
      setAccountError(true);
      setAccountErrorMessage(
        "Please choose a bank account for your automation.",
      );
    }
    if (!maximalAmount && bankAccount) {
      if (!regex.test(recommendedMaximalValue)) {
        setMaximalAmountError(true);
        return;
      } else {
        dispatch(setMaximalAmount(+recommendedMaximalValue));
        dispatch(setUserDefinedMaxPayment(Number(recommendedMaximalValue)));
        setContinueButtonClicked(true);
        dispatch(setShowOptimizationBlock(true));
      }
    }
    if (maximalAmount) {
      if (!regex.test(String(maximalAmount))) {
        setMaximalAmountError(true);
        return;
      } else if (maximalAmount) {
        setContinueButtonClicked(true);
        dispatch(setUserDefinedMaxPayment(Number(maximalAmount)));
        dispatch(setShowOptimizationBlock(true));
      }
    }
    if (showOptimizationBlock) {
      handleCreateAutomation();
      setFinishedAutomationFlow(true);
    }
  };

  //Variables
  let checkingAndSavingsAccounts = checkingAccounts.concat(savingsAccounts);
  const lessThanDesktopSize = window.innerWidth <= 1024;
  console.log("bankAccount", bankAccount);
  console.log("ksks", bankAccount && Object.keys(bankAccount).length !== 0);
  return (
    <>
      {finishAutomationFlow ? (
        <AutomationSetWrapUp />
      ) : (
        <Wrapper>
          <SignupNavbar
            step={6}
            handler={onBackHandler}
            showBackButton={true}
          />
          <div className="page__infoContainer">
            <div className="page__title liability_form_input_title">
              Now, let’s set up your automation.
            </div>
            <div className="page__subtitle">
              {showOptimizationBlock
                ? "Finally, please choose which optimization goal you want Cache to prioritize."
                : bankAccount && Object.keys(bankAccount).length !== 0
                ? "Please approve or set the maximal monthly amount that Cache can utilize to automatically pay off your credit cards."
                : "Next, please choose the bank account that you want Cache to use to automatically pay off your credit cards."}
            </div>

            <div>
              {showOptimizationBlock ? (
                <div className="optimizationPage_cotainer">
                  <div
                    className={classNames(
                      "connectAccounts_container",
                      "optimization_goal_block",
                    )}
                  >
                    <div className="page__title">Optimization goal</div>
                    <div className="page__subtitle">
                      When credit cards cannot be paid in full due to lack of
                      funds, we will prioritize payments to either minimize
                      interest charges or improve credit score. Default is set
                      to reducing interest payments, but you can prioritize
                      credit score below.
                    </div>
                    <div className="toggle_buttons_container">
                      <Button
                        className={classNames(
                          "demo_togglebutton",
                          optimizationMethod === "pay less interest" &&
                            "chosen_toggle_button",
                        )}
                        onClick={() => {
                          //   setOptomizationGoal("pay less interest");
                          dispatch(setOptimizationMethod("pay less interest"));
                          dispatch(
                            setNotChosenOptimizationMethod(
                              "improve credit score",
                            ),
                          );
                        }}
                        type={"primary-btn"}
                        style={{ opacity: 1 }}
                      >
                        Pay less interest
                      </Button>
                      <Button
                        className={classNames(
                          "demo_togglebutton",
                          optimizationMethod === "improve credit score" &&
                            "chosen_toggle_button",
                        )}
                        onClick={() => {
                          //   setOptomizationGoal("improve credit score");
                          dispatch(
                            setOptimizationMethod("improve credit score"),
                          );
                          dispatch(
                            setNotChosenOptimizationMethod("pay less interest"),
                          );
                        }}
                        type={"primary-btn"}
                        style={{ opacity: 1 }}
                      >
                        Improve credit score
                      </Button>
                    </div>
                  </div>
                  <div className="connectAccounts_container_optimization_info">
                    <div className="credit_car_and_maximalval">
                      <div className="account_card_container">
                        <DemoAccountCard
                          cacheType={bankAccount?.cacheType}
                          active={false}
                          className={classNames("accountCardDisabled")}
                          item={bankAccount}
                        />
                      </div>

                      <div
                        className="balanceInput__holder maximal_disabled_balance_holder"
                        style={{
                          position: "relative",
                          opacity: 0.5,
                          backgroundColor: "#303030",
                        }}
                      >
                        <DemoMaximalInput
                          className="maximum_amount_input"
                          style={{ paddingLeft: "20px" }}
                          value={maximalAmount}
                          onChangeHandler={(e: any) => {
                            console.log("changing input ");
                            console.log(e.target.value, "oiwejfuuiowefw");

                            dispatch(setMaximalAmount(e.target.value));
                            dispatch(
                              setUserDefinedMaxPayment(Number(e.target.value)),
                            );

                            setMaximalAmountError(false);
                          }}
                        />
                      </div>
                    </div>

                    <div className="arrows_icon_container optimization_arrow">
                      <img
                        className={
                          lessThanDesktopSize
                            ? "arrows_mobile_connected_accounts"
                            : "arrows_arrows_of_connected_accounts"
                        }
                        src={arrows}
                        alt="arrows icon"
                      />
                    </div>
                    <div className="credit_cards_container optimization_credit_cards setup_credit_cards">
                      {creditCards?.map((account: any) => (
                        <div key={account.id}>
                          <CreditCard
                            statementBalance={account.statementBalance}
                            disabled={true}
                            className={"disabled_creditCard"}
                            card={account}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ) : bankAccount && Object.keys(bankAccount).length !== 0 ? (
                <>
                  <div>
                    <div className="connectAccounts_container optimizationContainer">
                      <div className="page__title liability_form_input_title connect_accounts_title">
                        Max amount
                      </div>
                      <div className="page__subtitle max_subtitle">
                        After analyzing your accounts, Cache recommends that you
                        allocate a maximum of ${recommendedMaximalValue} to pay
                        towards your credit cards every month. This amount will
                        be continuously updated based on your balances. If you
                        have a different preference, you can manually set this
                        amount below.
                      </div>
                      <div className="balanceInput__holder">
                        <DemoMaximalInput
                          error={maximalAmountError}
                          value={maximalAmount}
                          className={classNames(
                            maximalAmountError ? "maximal_input_error" : "",
                            "maximum_amount_input",
                          )}
                          onChangeHandler={(e: any) => {
                            console.log("changing input ");
                            console.log(e.target.value, "oiwejfuuiowefw");

                            dispatch(setMaximalAmount(e.target.value));
                            setMaximalAmountError(false);
                          }}
                        />
                      </div>
                      {maximalAmountError && (
                        <div className="userName__errorMassage maximal_amount_error_message demo_form_error">
                          Please set the maximal amount for your automation.
                        </div>
                      )}
                    </div>

                    <div
                      className={
                        lessThanDesktopSize
                          ? "mobile_and_tablet_connect_accunts_container"
                          : "connectAccounts_container accounts_connected"
                      }
                    >
                      <div className="account_card_container">
                        <DemoAccountCard
                          cacheType={bankAccount?.cacheType}
                          active={false}
                          className={classNames("accountCardDisabled")}
                          item={bankAccount}
                        />
                      </div>
                      <div className="arrows_icon_container">
                        <img
                          className={
                            lessThanDesktopSize
                              ? "arrows_mobile_connected_accounts"
                              : "arrows_arrows_of_connected_accounts"
                          }
                          src={arrows}
                          alt="arrows icon"
                        />
                      </div>
                      <div className="credit_cards_container setup_credit_cards">
                        {creditCards?.map((account: any) => (
                          <div key={account.id}>
                            {" "}
                            <CreditCard
                              statementBalance={account?.statementBalance}
                              disabled={true}
                              className={"disabled_creditCard"}
                              card={account}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="pre_connected_accounts">
                  <div
                    className={classNames(
                      "connectAccounts_container_bank",
                      accountError && "error_connect_accounts_container",
                    )}
                  >
                    <div className="page__title liability_form_input_title connect_accounts_title">
                      Connect your bank account
                    </div>
                    <div className="connectAccBttn">
                      <Button
                        onClick={() => {
                          if (
                            checkingAccounts.length === 0 &&
                            savingsAccounts.length === 0
                          ) {
                            setAccountError(false);
                            setIsPlaidAuthOpen(true);
                          } else {
                            setAccountError(false);
                            handleModalOpen();
                          }
                        }}
                        type={"primary-btn"}
                        style={{ opacity: 1 }}
                      >
                        Connect Account
                      </Button>
                    </div>
                    <div className="footer_text">
                      Cache uses{" "}
                      <Link
                        style={{ color: "white", fontWeight: "700" }}
                        target="_blank"
                        to="https://plaid.com/"
                      >
                        {" "}
                        Plaid
                      </Link>{" "}
                      to securely connect to over 11,000 banks.
                    </div>
                  </div>
                  {accountError && (
                    <div className="userName__errorMassage error__message demo_form_error">
                      {accountErrorMessage}
                    </div>
                  )}
                  <div className="arrows_icon_container">
                    <img className="arrows" src={arrows} alt="arrows icon" />
                  </div>

                  <div className="bankCards_container selected_accounts_container">
                    {creditCards?.map((card: any) => (
                      <div key={card.id}>
                        {" "}
                        <CreditCard
                          statementBalance={card.statementBalance}
                          disabled={true}
                          className={"disabled_creditCard"}
                          card={card}
                        />
                      </div>
                    ))}
                  </div>
                  <div>
                    {showAccountsPopUp && (
                      <DemoModalAccountsList
                        title="Available Bank Accounts"
                        accountsList={checkingAndSavingsAccounts}
                        open={showAccountsPopUp}
                        relationType={AccountType.CHECKING}
                        onClose={() => {
                          handleModalClose();
                        }}
                        // setAccount={}
                        onPlaidAuthSuccess={onPlaidAuthSuccess}
                        setIsPlaidAuthLoading={setIsPlaidAuthLoading}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="button__holder">
            <Button
              onClick={onNextHandler}
              type={"primary-btn"}
              style={{ opacity: 1 }}
            >
              Continue
            </Button>
          </div>
          <PlaidAuth
            isOpen={isPlaidAuthOpen}
            success={onPlaidAuthSuccess}
            onExit={onPlaidAuthExit}
            withoutMarkup={true}
            setIsLoading={setIsPlaidAuthLoading}
            accountLoadErrorCallback={accountLoadErrorCallback}
            relationType={typeAcc}
          />
        </Wrapper>
      )}
    </>
  );
};

export default CreditCardAutomationSetUp;
