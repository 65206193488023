import "./demoTransferTable.css";
import status_done from "../../assets/status_done.svg";
import status_pending from "../../assets/status_pending.svg";
import status_error from "../../assets/status_error.svg";
import status_in_progress_yellow from "../../assets/status_in_progress_yellow.svg"; // Import yellow icon
import { getFormattedTransferDate } from "../../helpers/transfersUtils";
import { Tooltip } from "@mui/material";

interface OptimizedPaymentCard {
  cardID: string;
  cardMask: string;
  creditLimit: number;
  creditUtilization: number;
  outstandingBalance: number;
  statementBalance: number;
  minimumPaymentAmount: number;
  dueDate: string;
  originalName: string;
  bank: string;
  apr: number;
  paymentAmount: number;
  reason: string;
  remainingStatementBalance: number;
  newOutstandingBalance: number;
}

interface DemoTransfersTableProps {
  transfersData: OptimizedPaymentCard[] | undefined;
}

const formatCardDisplay = (bank: string, maskedNumber: string): string => {
  return `${bank} ${maskedNumber}`;
};

const getPaymentStatus = (dueDate: string, reason: string): string => {
  const today = new Date();
  const cardDueDate = new Date(dueDate);

  // Reset time portion for accurate date comparison
  today.setHours(0, 0, 0, 0);
  cardDueDate.setHours(0, 0, 0, 0);

  if (reason.toLowerCase().includes("initiated today")) {
    return "In Progress";
  }
  return "Scheduled (Tentative)";
};

const getPaymentDue = (dueDate: string, reason: string): string => {
  const today = new Date().toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "2-digit",
    timeZone: "UTC",
  });

  const cardDueDate = new Date(dueDate).toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "2-digit",
    timeZone: "UTC",
  });

  if (reason.toLowerCase().includes("initiated today")) {
    return today;
  }
  return cardDueDate;
};

const getStatusIcon = (status: string): string => {
  switch (status) {
    case "In Progress":
      return status_in_progress_yellow; // Use yellow icon for "In Progress"
    case "Scheduled (Tentative)":
      return status_pending;
    case "completed":
      return status_done;
    case "canceled":
    case "failed":
      return status_error;
    default:
      return status_pending;
  }
};

const getToolTipText = (status: string): string => {
  switch (status) {
    case "In Progress":
      return "Payment is being processed today.";
    case "Scheduled (Tentative)":
      return "Payment is scheduled for the future.";
    case "completed":
      return "Transfer complete.";
    case "canceled":
    case "failed":
      return "Transfer incomplete. A member of our team will contact you shortly.";
    default:
      return "Payment status unknown.";
  }
};

const getToolTipWidth = (status: string): number => {
  switch (status) {
    case "In Progress":
      return 200;
    case "Scheduled (Tentative)":
      return 200;
    case "completed":
      return 124;
    default:
      return 288;
  }
};

function DemoTransfersTable({ transfersData }: DemoTransfersTableProps) {
  const formattedTransfersData =
    transfersData
      ?.map((card) => ({
        createdAt: getPaymentDue(card.dueDate, card.reason),
        card: formatCardDisplay(card.bank, card.cardMask),
        amount: card.paymentAmount,
        status: getPaymentStatus(card.dueDate, card.reason),
      }))
      .sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(), // Sort closest to farthest
      ) || [];

  return (
    <>
      {formattedTransfersData?.length !== 0 && (
        <div className="table__container">
          <div className="title">Payments</div>
          <div className="tableBlock">
            <div className="table__holder">
              <div className="table__data">
                <div className="header__row__table">
                  <span>Date</span>
                  <span>Card</span>
                  <span>Amount</span>
                  <span>Status</span>
                </div>
                {formattedTransfersData.map(
                  ({ card, amount, status, createdAt }) => (
                    <div
                      className="row__table payments_row_table"
                      key={card + createdAt}
                    >
                      <span className="row_icon">
                        <Tooltip
                          title={getToolTipText(status)}
                          arrow
                          enterTouchDelay={0}
                          placement="bottom"
                          componentsProps={{
                            tooltip: {
                              sx: {
                                textAlign: "left",
                                width: `${getToolTipWidth(status)}px`,
                                color: "#FAFAFA",
                              },
                            },
                          }}
                        >
                          <img
                            src={getStatusIcon(status)}
                            className="status__icon"
                            alt="Status Icon"
                          />
                        </Tooltip>
                        {createdAt}
                      </span>
                      <span>{card}</span>
                      <span>
                        {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                        }).format(amount)}
                      </span>
                      <span>{status}</span>
                    </div>
                  ),
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default DemoTransfersTable;
