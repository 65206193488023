import React, { useEffect, useState } from "react";
import Button from "../../../elements/Button/Button";
import "./demoAddress.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import {
  changeAddress,
  changeApartment,
  changeCity,
  changeState,
  changeStreet,
  changeZipCode,
  setStep,
} from "../../../redux/sliceAuth";
import SignupNavbar from "../../../elements/SignupNavbar/SignupNavbar";
import { StepWizardChildProps } from "react-step-wizard";
import { getAuth } from "firebase/auth";
import { useUserEditMutation } from "../../../api/apiAuth";
import Address from "../../Address/Address";
import Wrapper from "../../../elements/Wrapper";
import { useMixpanel } from "../../../helpers/mixpanel";
import { useNavigate } from "react-router-dom";
// @ts-ignore

const DemoAddress: React.FC<Partial<StepWizardChildProps>> = ({}) => {
  const authInfo: any = useSelector<RootState>((state) => state.auth);
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();
  const auth = getAuth();
  const [editUser, editUserResult] = useUserEditMutation();
  const [isStreet, setIsStreet] = useState(!!authInfo?.street);
  const [showNextBtn, setShowNextBtn] = useState(false);
  const [formError, setFormError] = useState({
    address: false,
    apartment: false,
    city: false,
    zipCode: false,
    state: false,
  });
  const [errorMsg, setErrorMsg] = useState("");
  const [formData, setFormData] = useState({
    address: "",
    apartment: "",
    city: "",
    zipCode: "",
    state: "",
  });
  const navigate = useNavigate();
  const zipRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
  const stateRegex =
    /^(A[KLRZ]|C[AOT]|D[CE]|FL|GA|HI|I[ADLN]|K[SY]|LA|M[ADEINOST]|N[CDEHJMVY]|O[HKR]|PA|RI|S[CD]|T[NX]|UT|V[AT]|W[AIVY])$/;

  const showFormCallBack = (formVisible: boolean) => {
    setShowNextBtn(formVisible);
  };
  const showOptimizationBlock = JSON.parse(
    localStorage.getItem("true") || "false",
  );

  const firstName = useSelector<RootState>((state) => state.auth.firstName);
  useEffect(() => {
    localStorage.setItem(
      "optimizationBlock",
      JSON.stringify(showOptimizationBlock),
    );
  }, [showOptimizationBlock]);

  const resetErrorOnChange = () => {
    if (errorMsg) {
      setFormError({
        address: false,
        apartment: false,
        city: false,
        zipCode: false,
        state: false,
      });
      setErrorMsg("");
    }
  };

  useEffect(() => {
    setFormData({
      address: authInfo?.address || authInfo?.street,
      apartment: authInfo?.apartment,
      city: authInfo?.city,
      zipCode: authInfo?.zipCode,
      state: authInfo?.state,
    });
  }, [authInfo]);

  const getInvalidFields = (formData: any) => {
    const fields = [];
    for (let key in formData) {
      if (key === "apartment") {
        continue;
      }
      if (!formData[key]) {
        fields.push(key);
      } else if (
        (key === "zipCode" && !zipRegex.test(formData["zipCode"])) ||
        (key === "state" && !stateRegex.test(formData["state"]))
      ) {
        fields.push(key);
      }
    }
    return fields;
  };

  const onNextHandler = () => {
    const invalidFields = getInvalidFields(formData);
    if (invalidFields.length > 0) {
      setErrorMsg("Please complete all required fields.");
      setFormError({
        address: invalidFields.includes("address"),
        apartment: false,
        city: invalidFields.includes("city"),
        zipCode: invalidFields.includes("zipCode"),
        state: invalidFields.includes("state"),
      });
    } else {
      if (isStreet) {
        dispatch(changeAddress(""));
        dispatch(changeStreet(formData.address));
      } else {
        dispatch(changeStreet(""));
        dispatch(changeAddress(formData.address));
      }

      dispatch(changeApartment(formData.apartment));
      dispatch(changeCity(formData.city));
      dispatch(changeZipCode(formData.zipCode));
      dispatch(changeState(formData.state));

      const addressFieldName = isStreet ? "street" : "address";
      auth.currentUser
        ?.getIdToken()
        .then(async (tkn) => {
          const body = {
            [addressFieldName]: formData.address,
            apartment: formData.apartment || "",
            city: formData.city,
            zipCode: formData.zipCode,
            state: formData.state,
          };
          await editUser({ tkn, body }).then((res: any) => {
            if (res.error) {
              console.log("Error in editUser SignUpAddress.tsx: ", res.error);
            } else {
              navigate("/demo-ssn");
              mixpanel(
                "KYC_home_address",
                {
                  city: formData.city,
                  zipCode: formData.zipCode,
                  state: formData.state,
                  is_manual: isStreet,
                },
                tkn,
              );
            }
          });
        })
        .catch((error) => {
          console.log("Error in getIdToken SignUpAddress.tsx: ", error);
        });
    }
  };

  const onBackHandler = () => {
    localStorage.setItem("showOptimizationBlock", JSON.stringify(true));

    navigate("/set-up-automation");
  };

  return (
    <Wrapper>
      <SignupNavbar step={7} handler={onBackHandler} showBackButton={true} />
      <div className="page__infoContainer">
        <div className="address__infoContainer">
          <div className="page__title smaller-size">
            {`Just a few more details, ${firstName}. What’s your home address?`}
          </div>
          <Address
            showFormAlways={false}
            isStreet={isStreet}
            setIsStreet={setIsStreet}
            formError={formError}
            formData={formData}
            setFormData={setFormData}
            resetErrorOnChange={resetErrorOnChange}
            showFormCallBack={showFormCallBack}
          />
        </div>
        {!errorMsg ? (
          <>
            <div className="info__subtitle">
              We are required by law to ask these questions.
            </div>
            <div className="info__subtitle">
              This will help us keep your account secure.
            </div>
          </>
        ) : (
          <div className="info__subtitle error__msg">{errorMsg}</div>
        )}
      </div>
      {showNextBtn ? (
        <div className="button__holder">
          <Button
            onClick={() => onNextHandler()}
            type={"primary-btn"}
            style={{ opacity: 1 }}
            disabled={editUserResult.isLoading}
          >
            Continue
          </Button>
        </div>
      ) : (
        ""
      )}
      <input
        type="hidden"
        id="abandonedOnboardingStage"
        value={"kyc_address"}
      />
    </Wrapper>
  );
};

export default DemoAddress;
