import { useState, useCallback } from "react";
import MultiInput from "../../../elements/MultiInput/MultiInput";
import Button from "../../../elements/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import "../liabilityFlow.css";
import Wrapper from "../../../elements/Wrapper";
import SignupNavbar from "../../../elements/SignupNavbar/SignupNavbar";
import { StepWizardChildProps } from "react-step-wizard";
import "../demo.css";
import "./verifyPhoneNumber.css";
import { useNavigate } from "react-router-dom";
import { useVerifyOTPMutation } from "../../../api/apiSMS";
import { selectVerificationPhoneNumber } from "../../../redux/sliceCreditCards";
import { setPhoneNumberVerified } from "../../../redux/sliceCreditCards";

const VerifyPhoneNumber: React.FC<Partial<StepWizardChildProps>> = ({}) => {
  const [isEnabledNextBtn, setIsEnabledNextBtn] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [verifyOTP, { isLoading: isVerifying }] = useVerifyOTPMutation();
  const [error, setError] = useState(false);
  const onBackHandler = () => {
    navigate("/phone-number-form");
  };
  const [code, setCode] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const phoneNumber = useSelector(selectVerificationPhoneNumber);
  const isCodeValid = (code: string) => {
    return code && code.length === 6;
  };

  const resetErrorOnChange = () => {
    setError(false);
    setErrorMsg("");
  };

  const onOTPSubmit = useCallback(
    async (data: any) => {
      try {
        const response = await verifyOTP({
          phone_number: phoneNumber,
          code: data.code,
        }).unwrap();

        if (response.verification == "approved") {
          console.log("Phone number verified successfully!");
          dispatch(setPhoneNumberVerified(true));
          navigate("/demo-date-birth");
        } else {
          console.log("Invalid OTP code");
          setError(true);
          setErrorMsg("Wrong code entered. Please try again.");
        }
      } catch (err: any) {
        console.error(err.data?.message || "Failed to verify OTP");
      }
    },
    [isCodeValid],
  );

  const onNextHandler = async () => {
    if (code === "" || code.length < 6) {
      setError(true);
      setErrorMsg("Please enter the verification code.");
      return;
    }
    try {
      await onOTPSubmit({ code });
    } catch (error) {
      console.log("Error in onNextHandler, SignUpVerifyEmail.tsx: ", error);
    }
  };
  const formatPhoneNumber = (phone: string) => {
    return phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
  };

  const removePlusOne = (phone: string) => {
    return phone.replace("+1", "");
  };

  return (
    <Wrapper>
      <SignupNavbar step={3} handler={onBackHandler} showBackButton={true} />
      <div className="liability_form__infoContainer">
        <div className="page__title liability_form_input_title">
          Please verify your phone number.
        </div>
        <div className="page__subtitle ">
          We sent a text message to{" "}
          {formatPhoneNumber(removePlusOne(phoneNumber))} containing a 6-digit
          code. Enter it below to retrieve your credit card information. This
          will not affect your credit score.
        </div>
        <div className="customInput__holder">
          <MultiInput
            placeholder="------"
            numInputs={6}
            value={code}
            error={error}
            onChange={(value: any) => {
              setCode(value);
              resetErrorOnChange();
            }}
          />
        </div>
        {error ? (
          <div className="info__subtitle error__msg">{errorMsg}</div>
        ) : (
          ""
        )}
      </div>
      <div className="button__holder">
        <Button
          onClick={onNextHandler}
          type={"primary-btn"}
          disabled={!isEnabledNextBtn}
          style={{ opacity: 1 }}
        >
          Continue
        </Button>
      </div>
    </Wrapper>
  );
};
export default VerifyPhoneNumber;
