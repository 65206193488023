import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useLazyGetAllTransactionsQuery } from "../../api/apiDashboard";
import { getAuth } from "firebase/auth";
import { resetAuth } from "../../redux/sliceAuth";
import HeaderMenu from "../../elements/HeaderMenu/HeaderMenu";
import TransferTable from "../../elements/TransfersTable/TransfersTable";
import BackButton from "../../elements/BackButton/BackButton";
import "./transfersList.css";
import { AuthContext } from "../../context/AuthContext";
import { resetUserAutomation } from "../../redux/sliceUserAutomation";

function TransfersList() {
  const context = useContext(AuthContext);
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [getTransferData, getTransferResult] = useLazyGetAllTransactionsQuery();

  useEffect(() => {
    const { user } = context;
    if (!user) {
      dispatch(resetAuth());
      dispatch(resetUserAutomation());
      navigation("/login");
    } else {
      user.getIdToken().then(async (idToken: string) => {
        await getTransferData({ idToken });
      });
    }
  }, []);

  const { transactions } = getTransferResult?.data?.data ?? [];

  const onBackHandler = () => {
    navigation(-1);
  };

  return (
    <>
      <div className="automations__headerBlock">
        <HeaderMenu />
      </div>
      <div className="container">
        <div className="backButton">
          <BackButton onBack={onBackHandler} />
        </div>
        <div className="transfersList">
          <TransferTable transfersData={transactions ? transactions : []} />
        </div>
      </div>
    </>
  );
}

export default TransfersList;
