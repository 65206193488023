import classNames from "classnames";
import getBankLogo from "../../helpers/getBankIcon";
import "../BankCard/bankCard.css";
import "./creditCard.css";

const CreditCard = ({
  card,
  onSelectCard,
  className,
  isChecked,
  disabled,
  statementBalance,
}: any) => {
  const { creditCardNumberMasked, bank } = card;
  const bankName = bank;
  const bankLogo = getBankLogo(bank?.logo, bankName);
  const formattedBalance = statementBalance?.toLocaleString("en-US");

  const formatMask = (mask: string) => {
    if (mask.length < 4) return mask;
    return "*".repeat(mask.length - 4) + mask.slice(-4);
  };

  return (
    <div
      className={classNames(
        "bankCard",
        "demo_creditCard",
        className,
        {
          "bankCard--selected": isChecked,
        },
        disabled && "disabledCreditCard",
      )}
      onClick={() => onSelectCard(card.id)}
    >
      <div className="credit_data_choose_accs">
        <div className="bankCard__bankLogo">
          <img className="banklogoimg" src={bankLogo} alt="Bank Logo" />
        </div>
        <div className={classNames("bankCard__info")}>
          <div className="bankCard__bankName">{bankName}</div>
          <div className="demo_bank_card_num">
            {formatMask(creditCardNumberMasked)}
          </div>{" "}
          {/* Updated line */}
        </div>
      </div>

      <div className="bankCard__balance demo_bankCard__balance">${formattedBalance}</div>
    </div>
  );
};

export default CreditCard;
