import Loader from "../../../elements/Loader/Loader";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { resetAuth } from "../../../redux/sliceAuth";
import { useDispatch } from "react-redux";
import error_icon from "../../../assets/error_icon.svg";
import "./signUpUnableVerify.css";
import Button from "../../../elements/Button/Button";
import { AuthContext } from "../../../context/AuthContext";
import Wrapper from "../../../elements/Wrapper";
import { getAuth, signOut } from "firebase/auth";
import { resetUserAutomation } from "../../../redux/sliceUserAutomation";
import { useMixpanel } from "../../../helpers/mixpanel";

function SignUpUnableVerify() {
  const context = useContext(AuthContext);
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  }, []);

  const logout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        mixpanel("Logged_out");
        dispatch(resetAuth());
        dispatch(resetUserAutomation());
        navigation("/");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const { user } = context;
    if (!user) {
      dispatch(resetAuth());
      dispatch(resetUserAutomation());
      navigation("/login");
    }
  }, []);

  return (
    <Wrapper>
      <div className="page__infoContainer centered">
        <div className="error__icon-holder">
          <img src={error_icon} alt="Error Icon" />
        </div>

        <div className="unableVerify__infoContainer-title">
          Sorry, we still couldn’t verify your information.
        </div>
        <div className="unableVerify__infoContainer-subtitle">
          A member of the Cache team will reach out to you shortly via email.
        </div>
      </div>
      <div className="button__holder">
        <Button onClick={() => logout()} type={"primary-btn"}>
          OK
        </Button>
      </div>
      <Loader loading={false} />
      <input type="hidden" id="abandonedOnboardingStage" value={"kyc_fail2"} />
    </Wrapper>
  );
}

export default SignUpUnableVerify;
