import { useEffect, useState, useCallback } from "react";
import Button from "../../../elements/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import Wrapper from "../../../elements/Wrapper";
import SignupNavbar from "../../../elements/SignupNavbar/SignupNavbar";
import { StepWizardChildProps } from "react-step-wizard";
import TextInput from "../../../elements/Input/TextInput";
import { useNavigate } from "react-router-dom";
import { useSendOTPMutation } from "../../../api/apiSMS";
import "../CreditCardAutomationSetUp/creditCardAutomationSetUp.css";
import "../demo.css";
import "./phoneNumberForm.css";
import "../liabilityFlow.css";

//redux
import { setVerificationPhoneNumber } from "../../../redux/sliceCreditCards";
import { RootState } from "../../../redux/store";

const PhoneNumberForm: React.FC<Partial<StepWizardChildProps>> = ({}) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const dispatch = useDispatch();
  const phoneRegex =
    /^(?:\+?1[-\s.]?)?(?:\(?([2-9]\d{2})\)?[-\s.]?)([2-9]\d{2})([-\s.]?\d{4})$/;
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [sendOTP, { isLoading: isSending }] = useSendOTPMutation();
  const firstName: any = useSelector<RootState>(
    (state) => state.auth.firstName,
  );

  const isValidUSPhone = (phone: string): boolean => {
    return phoneRegex.test(phone);
  };

  const formatPhoneE164 = (phone: string): string => {
    const digitsOnly = phone.replace(/\D/g, "");
    if (digitsOnly.length === 11 && digitsOnly.startsWith("1")) {
      return `+${digitsOnly}`;
    }
    if (digitsOnly.length === 10) {
      return `+1${digitsOnly}`;
    }
    return phone;
  };

  const onNextHandler = () => {
    if (phoneNumber === "") {
      setError(true);
      setErrorMessage("Please enter your phone number.");
      return;
    }
    if (!isValidUSPhone(phoneNumber) && phoneNumber !== "") {
      setError(true);
      setErrorMessage("Invalid phone number. Please try again.");
      return;
    } else {
      const formattedPhone = formatPhoneE164(phoneNumber);
      dispatch(setVerificationPhoneNumber(formattedPhone));
      const sendCodeResponse = sendCode({ phone_number: formattedPhone });
      navigate("/phone-number-verification");
    }
  };

  const sendCode = useCallback(
    async (data: any) => {
      try {
        const response = await sendOTP(data).unwrap();
        // if (response.status === "pending") {
        //   setPhoneNumber(data.phone_number);
        // }
      } catch (err: any) {
        setError(err.data?.message || "Failed to send OTP");
      }
    },
    [sendOTP],
  );

  const handleChange = (e: any) => {
    setPhoneNumber(e.target.value);
  };
  const handleClickPhoneInput = (e: any) => {
    setError(false);
    setErrorMessage("");
  };

  return (
    <Wrapper>
      <SignupNavbar step={2} showBackButton={false} />
      <div className="page__infoContainer phone__form_container">
        <div className="page__title liability_form_input_title">
          Welcome, {firstName}! Please enter your phone number.
        </div>
        <div className="page__subtitle phone_form_subtitle">
          We will send a text message to this number to get your authorization
          to retrieve your credit card information. This will not affect your
          credit score.
        </div>
        <div
          className={
            error ? "customPhoneInput__holder_with_error" : "phonenumber_input"
          }
        >
          <TextInput
            fullWidth
            label="Phone Number"
            name="phoneNumber"
            onChange={handleChange}
            onClick={handleClickPhoneInput}
          />
        </div>
        {error && (
          <div className="userName__errorMassage error__message demo_form_error">
            {errorMessage}
          </div>
        )}
      </div>
      <div className="button__holder">
        <Button
          onClick={onNextHandler}
          type={"primary-btn"}
          style={{ opacity: 1 }}
        >
          Continue
        </Button>
      </div>
    </Wrapper>
  );
};
export default PhoneNumberForm;
