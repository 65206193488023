import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import e from "express";
import { setMaximalAmountValue } from "./sliceUserAutomation";

interface CreditCard {
  id: string;
  creditCardNumberMasked: string;
  creditLimit: number;
  creditUtilization: number;
  outstandingBalance: number;
  statementBalance: number;
  minimumPaymentAmount: number;
  dueDate: string;
  originalName: string;
  apr: number;
  bank: string;
}

interface CreditCardState {
  creditCardsExperienceChosen: boolean;
  creditCards: CreditCard[];
  selectedCreditCards: CreditCard[];
  accountId: string | null;
  userDefinedMaxPayment: number;
  optimizationMethod: "pay less interest" | "improve credit score";
  accountBalance: number;
  liabilityAutomation: LiabilityAutomation | null;
  notChosenOptimizationMethod: string;
  phoneNumber: string;
  phoneNumberVerified: boolean;
  bankAccount: any;
  showOptimizationBlock: boolean;
  selectAccount: boolean;
  maximalAmount: number;
}

const initialState: CreditCardState = {
  creditCardsExperienceChosen: false,
  creditCards: [],
  selectedCreditCards: [],
  accountId: null,
  userDefinedMaxPayment: 0,
  optimizationMethod: "pay less interest",
  accountBalance: 0,
  liabilityAutomation: null,
  notChosenOptimizationMethod: "improve credit score",
  phoneNumber: "",
  phoneNumberVerified: false,
  bankAccount: null,
  showOptimizationBlock: false,
  selectAccount: false,
  maximalAmount: 0,
};

interface OptimizedPaymentCard {
  cardID: string;
  cardMask: string;
  creditLimit: number;
  creditUtilization: number;
  outstandingBalance: number;
  statementBalance: number;
  minimumPaymentAmount: number;
  dueDate: string;
  originalName: string;
  bank: string;
  apr: number;
  paymentAmount: number;
  reason: string;
  remainingStatementBalance: number;
  newOutstandingBalance: number;
}

interface OptimizedPayments {
  originalBudget: number;
  totalPaymentsMade: number;
  remainingBudget: number;
  cards: OptimizedPaymentCard[];
}

interface LiabilityAutomation {
  id: string;
  userId: string;
  accountId: string;
  createdAt: string;
  updatedAt: string;
  status: string;
  userDefinedMaxPayment: number;
  optimizationMethod: string;
  accountBalance: number;
  creditCards: CreditCard[];
  optimizedPayments: OptimizedPayments;
}

export const creditCardSlice = createSlice({
  name: "creditCards",
  initialState,
  reducers: {
    //set show optimization block
    setShowOptimizationBlock: (state, action: PayloadAction<boolean>) => {
      state.showOptimizationBlock = action.payload;
    },

    //set select account
    setSelectAccount: (state, action: PayloadAction<boolean>) => {
      state.selectAccount = action.payload;
    },

    //set maximal amount
    setMaximalAmount: (state, action: PayloadAction<number>) => {
      state.maximalAmount = action.payload;
    },

    //set bank account
    setBankAccount: (state, action: PayloadAction<any>) => {
      state.bankAccount = action.payload;
    },
    // set phone number verified
    setPhoneNumberVerified: (state, action: PayloadAction<boolean>) => {
      state.phoneNumberVerified = action.payload;
    },
    //set phone number
    setVerificationPhoneNumber: (state, action: PayloadAction<string>) => {
      state.phoneNumber = action.payload;
    },
    // set chosedn experience
    setChosenExperience: (state, action: PayloadAction<boolean>) => {
      state.creditCardsExperienceChosen = action.payload;
    },
    // Set all credit cards
    setCreditCards: (state, action: PayloadAction<CreditCard[]>) => {
      state.creditCards = action.payload;
    },

    // Set selected credit cards
    setSelectedCreditCards: (state, action: PayloadAction<CreditCard[]>) => {
      state.selectedCreditCards = action.payload;
    },

    // Add a single credit card to selected
    addSelectedCreditCard: (state, action: PayloadAction<CreditCard>) => {
      state.selectedCreditCards.push(action.payload);
    },

    // Remove a single credit card from selected
    removeSelectedCreditCard: (state, action: PayloadAction<string>) => {
      state.selectedCreditCards = state.selectedCreditCards.filter(
        (card) => card.id !== action.payload,
      );
    },

    // Set account ID
    setAccountId: (state, action: PayloadAction<string>) => {
      state.accountId = action.payload;
    },

    // Set user defined max payment
    setUserDefinedMaxPayment: (state, action: PayloadAction<number>) => {
      state.userDefinedMaxPayment = action.payload;
    },
    setNotChosenOptimizationMethod: (state, action: PayloadAction<string>) => {
      state.notChosenOptimizationMethod = action.payload;
    },

    // Set optimization method
    setOptimizationMethod: (
      state,
      action: PayloadAction<"pay less interest" | "improve credit score">,
    ) => {
      state.optimizationMethod = action.payload;
    },

    // Set account balance
    setAccountBalance: (state, action: PayloadAction<number>) => {
      state.accountBalance = action.payload;
    },

    // Set liability automation
    setLiabilityAutomation: (
      state,
      action: PayloadAction<LiabilityAutomation | null>,
    ) => {
      state.liabilityAutomation = action.payload;
    },

    // Reset all state
    resetCreditCardState: (state) => {
      Object.assign(state, initialState);
    },

    // Reset selected cards only
    resetSelectedCards: (state) => {
      state.selectedCreditCards = [];
    },
  },
});

// Selectors

export const selectBankAccount = (state: { creditCards: CreditCardState }) =>
  state.creditCards.bankAccount;

export const selectCreditCards = (state: { creditCards: CreditCardState }) =>
  state.creditCards.creditCards;

export const selectSelectedCreditCards = (state: {
  creditCards: CreditCardState;
}) => state.creditCards.selectedCreditCards;

export const selectAccountId = (state: { creditCards: CreditCardState }) =>
  state.creditCards.accountId;

export const selectUserDefinedMaxPayment = (state: {
  creditCards: CreditCardState;
}) => state.creditCards.userDefinedMaxPayment;

export const selectOptimizationMethod = (state: {
  creditCards: CreditCardState;
}) => state.creditCards.optimizationMethod;

export const selectAccountBalance = (state: { creditCards: CreditCardState }) =>
  state.creditCards.accountBalance;

export const selectLiabilityAutomation = (state: {
  creditCards: CreditCardState;
}) => state.creditCards.liabilityAutomation;

export const selectCreditCardsExperienceChosen = (state: {
  creditCards: CreditCardState;
}) => state.creditCards.creditCardsExperienceChosen;

export const selectVerificationPhoneNumber = (state: {
  creditCards: CreditCardState;
}) => state.creditCards.phoneNumber;

export const selectShowOptimizationBlock = (state: {
  creditCards: CreditCardState;
}) => state.creditCards.showOptimizationBlock;

export const selectSelectAccount = (state: { creditCards: CreditCardState }) =>
  state.creditCards.selectAccount;

export const selectMaximalAmount = (state: { creditCards: CreditCardState }) =>
  state.creditCards.maximalAmount;

// Export actions
export const {
  setChosenExperience,
  setCreditCards,
  setSelectedCreditCards,
  addSelectedCreditCard,
  removeSelectedCreditCard,
  setAccountId,
  setUserDefinedMaxPayment,
  setOptimizationMethod,
  setAccountBalance,
  setLiabilityAutomation,
  resetCreditCardState,
  resetSelectedCards,
  setNotChosenOptimizationMethod,
  setVerificationPhoneNumber,
  setPhoneNumberVerified,
  setBankAccount,
  setSelectAccount,
  setMaximalAmount,
  setShowOptimizationBlock,
} = creditCardSlice.actions;

// Export reducer
export default creditCardSlice.reducer;
