import Lottie from "lottie-react";
import animation_scan from "../../../assets/document_scan.json";
import "./demoVerification.css";

const DemoAccountVerification = () => {
  return (
    <div className="verifyAccount__infocontainer">
      <div className="verifyAcc__icon">
        <Lottie animationData={animation_scan} loop={true} />
      </div>
      <p className="verifyAcc__subtitle">
        Thank you. You’ve completed the onboarding process.{" "}
      </p>
      <p className="verifyAcc__subtitle">We’re verifying your information. </p>
    </div>
  );
};

export default DemoAccountVerification;
