import classNames from "classnames";
import getBankLogo from "../../helpers/getBankIcon";
import "../BankCard/bankCard.css";
import "./demoCreditCardForDashboard.css";

const DemoCreditCardForDashboard = ({
  footerText,
  account,
  className,
  isChecked,
  disabled,
  balance,
  apr,
  minAmountDue,
  dueDate,
  utilizationRate,
  bank,
}: any) => {
  const { mask, institution } = account;
  const bankName = institution?.name;
  const bankLogo = getBankLogo(institution?.logo, bank);
  const formatMask = (mask: string) => {
    if (mask.length < 4) return mask;
    return "*".repeat(mask.length - 4) + mask.slice(-4);
  };

  return (
    <>
      <div
        className={classNames(
          "bankCard",
          "demo_creditCardDashboard",
          className,
          {
            "bankCard--selected": isChecked,
          },
          disabled && "disabledCreditCard",
        )}
      >
        <div className="credit_dataDemo_dashboard">
          <div className="bankCard__bankLogo">
            <img src={bankLogo} alt="Bank Logo" />
          </div>
          <div className={classNames("bankCard__info demo_info")}>
            <div className="bankCard__bankName creditCard_name">{bankName}</div>
            <div className="bankCard__number">{formatMask(mask)}</div>
          </div>
        </div>

        <div className="parent_block">
          <div className="colBlock">
            <div className="info_cont">
              <div className="bankCard__value">${balance.toLocaleString()}</div>
              <div className="remainingStatementBalanceText status">
                Remaining Statement Balance
              </div>
            </div>
            <div className="info_cont">
              <div className="bankCard__value">${balance.toLocaleString()}</div>
              <div className="currentBalanceText status">Current Balance</div>
            </div>
          </div>

          <div className="colBlock">
            <div className="info_cont">
              <div className="bankCard__value">${minAmountDue.toFixed(2)}</div>
              <div className="minAMountDueText status">Min Amount Due</div>
            </div>
            <div className="info_cont">
              <div className="bankCard__value">{dueDate}</div>
              <div className="due_date_text">Due Date</div>
            </div>
          </div>

          <div className="colBlock">
            <div className="info_cont">
              <div className="bankCard__value">{apr}%</div>
              <div className="aprText">APR</div>
            </div>
            <div className="info_cont">
              <div className="bankCard__value">{utilizationRate}%</div>
              <div className="utilizationRateText">Utilization Rate</div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer_creditCard_text">{footerText}.</div>
    </>
  );
};

export default DemoCreditCardForDashboard;
