import { AppBar, Container, Grid, Toolbar, Stack, Button } from "@mui/material";
import logo from "../assets/cache_logo.svg";
import { Link } from "react-router-dom";
import Banner from "./Banner/Banner";
import { AVOCADO_PROMOTION_TEXT } from "../helpers/constants";
import { useMixpanelPublic } from "../helpers/mixpanel";

function Header({
  position = "relative",
  showBanner = false,
  showUtmBanner = false,
  onClickedBannerModal,
  children,
}: any) {
  const mixpanelPublic = useMixpanelPublic();
  const nonClickableLogo = [
    "/signup",
    "/signup-retry-verify",
    "/signup-unable-verify",
  ].includes(location.pathname);

  return (
    <AppBar position={position} className="app-header">
      {showUtmBanner && onClickedBannerModal && (
        <Banner
          text={AVOCADO_PROMOTION_TEXT}
          onClick={onClickedBannerModal}
          hasImage={true}
        />
      )}

      <Toolbar>
        <Container className="header-container">
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            className="l_header"
          >
            <Grid item>
              {nonClickableLogo ? (
                <img src={logo} alt="Logo" className="logo__icon" />
              ) : (
                <Link to="/" onClick={() => window.scrollTo(0, 0)}>
                  <img src={logo} alt="Logo" className="logo__icon" />
                </Link>
              )}
            </Grid>
            <Grid item>
              {children ? (
                children
              ) : (
                <Stack direction="row" justifyContent="end" spacing={2}>
                  <Button
                    component={Link}
                    to="/login"
                    className="headedBtn"
                    color="inherit"
                  >
                    Login
                  </Button>
                  <Button
                    component={Link}
                    to="/demo-page"
                    className="headedBtn getStartedBtn"
                    variant="contained"
                    onClick={() => {
                      mixpanelPublic("Started");
                    }}
                  >
                    Get Started
                  </Button>
                </Stack>
              )}
            </Grid>
          </Grid>
        </Container>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
