import { createSlice } from "@reduxjs/toolkit";

interface StateAuth {
  email: string;
  firstName: string;
  lastName: string;
  address: string;
  street: string;
  apartment: string;
  city: string;
  zipCode: string;
  state: string;
  birthday: string;
  birthdayDay: number | null;
  birthdayMonth: number | null;
  birthdayYear: number | null;
  ssnOne: string;
  ssnTwo: string;
  ssnThree: string;
  ssnFour: string;
  ssnFive: string;
  ssnSix: string;
  ssnSeven: string;
  ssnEight: string;
  ssnNine: string;
  url: string;
  errors: any;
  marketingNotifications: boolean;
  confirmedCachePolicy: boolean;
  step: number;
}

const initialState: StateAuth = {
  email: "",
  firstName: "",
  lastName: "",
  address: "",
  street: "",
  apartment: "",
  city: "",
  zipCode: "",
  state: "",
  birthday: "",
  birthdayDay: null,
  birthdayMonth: null,
  birthdayYear: null,
  ssnOne: "",
  ssnTwo: "",
  ssnThree: "",
  ssnFour: "",
  ssnFive: "",
  ssnSix: "",
  ssnSeven: "",
  ssnEight: "",
  ssnNine: "",
  url: "",
  errors: [],
  marketingNotifications: false,
  confirmedCachePolicy: false,
  step: 1,
};

export const sliceAuth = createSlice({
  name: "auth",
  initialState: initialState as StateAuth,
  reducers: {
    changeEmail: (state, { payload }) => {
      state.email = payload;
    },
    changeFirstName: (state, { payload }) => {
      state.firstName = payload;
    },
    changeLastName: (state, { payload }) => {
      state.lastName = payload;
    },
    changeAddress: (state, { payload }) => {
      state.address = payload;
    },
    changeStreet: (state, { payload }) => {
      state.street = payload;
    },
    changeApartment: (state, { payload }) => {
      state.apartment = payload;
    },
    changeCity: (state, { payload }) => {
      state.city = payload;
    },
    changeZipCode: (state, { payload }) => {
      state.zipCode = payload;
    },
    changeState: (state, { payload }) => {
      state.state = payload;
    },
    changeBirthday: (state, { payload }) => {
      state.birthday = payload;
    },
    changeBirthdayDay: (state, { payload }) => {
      state.birthdayDay = payload;
    },
    changeBirthdayMonth: (state, { payload }) => {
      state.birthdayMonth = payload;
    },
    changeBirthdayYear: (state, { payload }) => {
      state.birthdayYear = payload;
    },
    changeSsnOne: (state, { payload }) => {
      state.ssnOne = payload;
    },
    changeSsnTwo: (state, { payload }) => {
      state.ssnTwo = payload;
    },
    changeSsnThree: (state, { payload }) => {
      state.ssnThree = payload;
    },
    changeSsnFour: (state, { payload }) => {
      state.ssnFour = payload;
    },
    changeSsnFive: (state, { payload }) => {
      state.ssnFive = payload;
    },
    changeSsnSix: (state, { payload }) => {
      state.ssnSix = payload;
    },
    changeSsnSeven: (state, { payload }) => {
      state.ssnSeven = payload;
    },
    changeSsnEight: (state, { payload }) => {
      state.ssnEight = payload;
    },
    changeSsnNine: (state, { payload }) => {
      state.ssnNine = payload;
    },
    changeErrors: (state, { payload }) => {
      state.errors = payload;
    },
    changeUrl: (state, { payload }) => {
      state.url = payload;
    },
    changeMarketingNotification: (state, { payload }) => {
      state.marketingNotifications = payload;
    },
    changeConfirmedCachePolicy: (state, { payload }) => {
      state.confirmedCachePolicy = payload;
    },
    setStep: (state, { payload }) => {
      state.step = payload;
    },
    updateInfo: (state, { payload }) => {
      state.email = payload.email;
      state.address = payload.address;
      state.street = payload.street;
      state.apartment = payload.apartment;
      state.birthday = payload.birthday;
      state.birthdayDay = payload.birthdayDay;
      state.birthdayMonth = payload.birthdayMonth;
      state.birthdayYear = payload.birthdayYear;
      state.city = payload.city;
      state.email = payload.email;
      state.firstName = payload.firstName;
      state.lastName = payload.lastName;
      state.ssnSix = payload.ssnSix;
      state.ssnSeven = payload.ssnSeven;
      state.ssnEight = payload.ssnEight;
      state.ssnNine = payload.ssnNine;
      state.state = payload.state;
      state.zipCode = payload.zipCode;
      state.marketingNotifications = payload.marketingNotifications;
      state.confirmedCachePolicy = payload.confirmedCachePolicy;
    },
    resetAuth: () => {
      return initialState;
    },
  },
});

export const {
  changeEmail,
  changeFirstName,
  changeLastName,
  changeAddress,
  changeStreet,
  changeApartment,
  changeCity,
  changeZipCode,
  changeState,
  changeBirthday,
  changeBirthdayDay,
  changeBirthdayMonth,
  changeBirthdayYear,
  changeSsnOne,
  changeSsnTwo,
  changeSsnThree,
  changeSsnFour,
  changeSsnFive,
  changeSsnSix,
  changeSsnSeven,
  changeSsnEight,
  changeSsnNine,
  changeErrors,
  changeUrl,
  updateInfo,
  resetAuth,
  changeMarketingNotification,
  setStep,
} = sliceAuth.actions;

export default sliceAuth.reducer;
