import classNames from "classnames";
import "./CustomCheckbox.css";

function CustomCheckbox({ label, onChange, checked, className }: any) {
  return (
    <label className={classNames("question", className)}>
      <input
        type="checkbox"
        className="questionCheckbox__input"
        onChange={onChange}
        checked={checked}
        value={typeof label === "string" ? label : ""}
      />
      <span className="checkbox__custom"></span>
      {label}
    </label>
  );
}

export default CustomCheckbox;
