import Wrapper from "../../../elements/Wrapper";
import "./automationSetWrapUp.css";
import successAnimation from "../../../assets/success_animation.json";
import Lottie from "lottie-react";

const AutomationSetWrapUp = () => {
  return (
    <Wrapper>
      <div className="lottie_animation">
        <Lottie
          animationData={successAnimation}
          loop={false}
          autoplay={true}
          height={window.innerHeight}
          width={window.innerWidth}
        />
      </div>
      <div className="wrapUpTitle">
        Your automation is set! <br /> Time to wrap up...
      </div>
    </Wrapper>
  );
};

export default AutomationSetWrapUp;
