import { useEffect } from "react";
import getBankLogo from "../../helpers/getBankIcon";
import "./demoBankCard.css";
function DemoBankCard({
  account,
  onSelectAccount,
  currentAutomation,
  updateIsEnabledAutomation,
}: any) {
  const { mask, isUsedInAutomation, institution, id } = account;
  const bankName = institution?.name;
  const bankLogo = getBankLogo(institution?.logo, institution?.id);

  useEffect(() => {
    if (isUsedInAutomation) {
      let enabled = false;
      if (
        [
          currentAutomation?.checkingAccountId,
          currentAutomation?.savingAccountId,
        ].includes(id)
      ) {
        enabled = true;
      }
      updateIsEnabledAutomation(account.id, enabled);
    } else {
      updateIsEnabledAutomation(account.id, true);
    }
    if (!currentAutomation) {
      updateIsEnabledAutomation(account.id, !isUsedInAutomation);
    }
  }, []);
  return (
    <div
      className={`bankCard`}
      onClick={() => {
        onSelectAccount(account);
      }}
      style={{
        cursor: "pointer",
      }}
    >
      <div className="bankCard__info">
        <div className="bankCard__bankName">{bankName}</div>
        <div className="bankCard__number">{mask}</div>
      </div>
      <div className="bankCard__bankLogo">
        <img src={bankLogo} alt="Bank Logo" />
      </div>
    </div>
  );
}

export default DemoBankCard;
